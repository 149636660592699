import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
  TranslateService
} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {StartComponent} from './components/start/start.component';
import {OptionComponent} from './components/option/option.component';
import {CaseDescriptionComponent} from './components/case-description/case-description.component';
import {InquirySelectComponent} from './components/inquiry-select/inquiry-select.component';
import {MapComponent} from './components/map/map.component';
import {OtherLocationComponent} from './components/other-location/other-location.component';
import {PolicyownerComponent} from './components/policyowner/policyowner.component';
import {DatesComponent} from './components/dates/dates.component';
import {PurposeComponent} from './components/purpose/purpose.component';
import {PersonalInfoComponent} from './components/personal-info/personal-info.component';
import {SummaryComponent} from './components/summary/summary.component';
import {ThanksComponent} from './components/thanks/thanks.component';
import {ErrorComponent} from './components/error/error.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CaseDataService} from './services/case-data.service';
import {initialConfig, NEW_CONFIG, NgxMaskModule} from 'ngx-mask';
import {NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EnvServiceProvider} from './services/env.service.provider';
import {ChildOptionComponent} from './components/child-option/child-option.component';
import {ChildInfoComponent} from './components/child-info/child-info.component';
import {PreferredChannelComponent} from './components/preferred-channel/preferred-channel.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {MinimalSelectorComponent} from './components/shared/components/minimal-selector/minimal-selector.component';
import {InsuranceInMyNameComponent} from './components/insurance-in-my-name/insurance-in-my-name.component';
import {DatePipe} from '@angular/common';
import {FrontendLoggingModule} from 'frontend-logging-travelcare';
import {SosCommonUiModule} from 'sos-common-ui';
import {SOSCommonGoogleConfig, SosCommonUiGoogleModule} from 'sos-common-ui-google';
import {EnvService} from './services/env.service';
import {ApiModule} from './shared/api/api.module';
import {InfoBarComponent} from './components/info-bar/info-bar.component';
import {AimService} from './services/aim.service';
import { InsuranceSelectComponent } from './components/insurance-select/insurance-select.component';
import {appInitializerFactory} from './components/shared/utils/translate-utils';
import {UrlGuardService} from './services/url-guard.service';
import {catchError, Observable} from 'rxjs';
import {CaseSessionData} from './data/casedata.model';
import {Router} from '@angular/router';

export function HttpLoaderFactory(
  http: HttpClient,
  envService: EnvService,
  caseDataService: CaseDataService,
  router: Router): TranslateLoader {
  return new TranslationServiceLoader(http, envService, caseDataService, router);
}

export class TranslationServiceLoader extends TranslateLoader{
  constructor(private http: HttpClient, private envService: EnvService, private caseDataService: CaseDataService, private router: Router) {
    super();
  }

  getTranslation(lang: string): Observable<object> {
    let queryParams = {};
    if (this.caseDataService.getSessionData(CaseSessionData.SHOW_KEYS) === CaseSessionData.SHOW_KEYS){
      queryParams = {showKeys: true};
    }
    const path = 'translations/translation/' + this.envService.translationEnv + '/meepoint/' + lang;
    return this.http.get<object>(path, {params: queryParams}).pipe(catchError((err, caught) => {
      console.error('Failed to read translations', err, caught);
      this.router.navigate(['error']);
      throw err;
    }));
  }
}

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): object | string  {
    const interpolation = params.interpolateParams as object;
    if (interpolation) {
      return interpolation['Default' as keyof object] || params.key;
    }
    return params.key;
  }
}

const blackListForFrontendLogging: string[] = ['https://maps.googleapis.com', 'https://www.googleapis.com'];

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    OptionComponent,
    CaseDescriptionComponent,
    InquirySelectComponent,
    MapComponent,
    OtherLocationComponent,
    PolicyownerComponent,
    DatesComponent,
    PurposeComponent,
    PersonalInfoComponent,
    SummaryComponent,
    ThanksComponent,
    ErrorComponent,
    ChildOptionComponent,
    ChildInfoComponent,
    PreferredChannelComponent,
    InsuranceInMyNameComponent,
    MinimalSelectorComponent,
    InfoBarComponent,
    InsuranceSelectComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, EnvService, CaseDataService, Router]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler, useClass: MissingTranslationHelper
      }
    }),
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    NgbModule,
    NgbDropdownModule,
    SosCommonUiModule.forRoot({enableCoBranding: true}),
    NgxMaskModule,
    SosCommonUiGoogleModule,
    FrontendLoggingModule.forRoot({rootUrl: '/logapi'}, {urlBlacklist: blackListForFrontendLogging}),
    ApiModule.forRoot({
      rootUrl: 'api'
    })
  ],
  providers: [
    CaseDataService,
    UrlGuardService,
    EnvServiceProvider,
    DatePipe,
    TranslatePipe,
    {provide: NEW_CONFIG, useValue: {
        patterns: {...initialConfig.patterns, ...{
            [1]:
              {pattern: new RegExp('[1-9]')}
          }
        }
      }},
    {
      provide: SOSCommonGoogleConfig,
      useFactory: (envService: EnvService): SOSCommonGoogleConfig => {
        return {googleAPIKey: envService.GOOGLE_API_KEY};
      },
      deps: [EnvService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setHeader,
      multi: true,
      deps: [EnvService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: enableGuard,
      multi: true,
      deps: [UrlGuardService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (env: EnvService) => () => {
        const url = `${env.aimUrl}/aim/public/message/${AimService.applicationName}`;
        blackListForFrontendLogging.push(url);
      },
      deps: [EnvService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
export function setHeader(moduleConfig: EnvService) {
  return () => {
    return new Promise<void>((resolve, reject) => {
      let googleScript = document.createElement('script');
      googleScript.src = 'https://www.googletagmanager.com/gtag/js?id='+moduleConfig.gTag;
      googleScript.type = 'text/javascript';
      googleScript.async = false;
      const headTag = document.getElementsByTagName('head')[0];

      googleScript.onload = (ev)=>{
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          // @ts-ignore
          window.dataLayer.push(arguments);
        }
        // @ts-ignore
        gtag('js', new Date());
        // @ts-ignore
        gtag('config', moduleConfig.gTag);
        resolve();
      };

      headTag.appendChild(googleScript);
    });
  }
}
export function enableGuard(urlGuardService: UrlGuardService): ()=>void {
  return () => {
    urlGuardService.enable();
  };
}
