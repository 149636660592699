import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import {filter} from 'rxjs/operators';
import {EnvService} from './services/env.service';
import {CaseDataService} from './services/case-data.service';
import {v4 as uuidv4} from 'uuid';
import {CssService, LanguageService, LanguageType} from 'sos-common-ui';
import {LoggingService} from 'frontend-logging-travelcare';
import {Language} from './data/language';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {MDC} from "./data/mdc";
import {StartComponent} from "./components/start/start.component";
import {CaseSessionData} from "./data/casedata.model";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
  public readonly SESSION_ID_KEY = 'session_id';

  title = 'Create Case';
  translateSubscription: Subscription | undefined;

  languageTypes: LanguageType[] =
    [{translationKeyShort: 'general.language.short.da', translationKeyLong: 'general.language.long.da', languageCode: 'da'},
      {translationKeyShort: 'general.language.short.no', translationKeyLong: 'general.language.long.no', languageCode: 'no'},
      {translationKeyShort: 'general.language.short.sv', translationKeyLong: 'general.language.long.sv', languageCode: 'sv'},
      {translationKeyShort: 'general.language.short.fi', translationKeyLong: 'general.language.long.fi', languageCode: 'fi'},
      {translationKeyShort: 'general.language.short.en', translationKeyLong: 'general.language.long.en', languageCode: 'en'}];

  constructor(private translate: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              private activatedRoute: ActivatedRoute,
              private languageService: LanguageService,
              private envService: EnvService,
              private loggingService: LoggingService,
              private caseDataService: CaseDataService,
              private cssService: CssService) {
    loggingService.addMDC('application', 'meepoint-ui');
    let sessionID = caseDataService.getSessionData(this.SESSION_ID_KEY);
    if (!sessionID) {
      sessionID = uuidv4();
      caseDataService.saveSessionData(this.SESSION_ID_KEY, sessionID);
    }
    loggingService.setCorrelationID(sessionID);
  }



  ngOnInit(): void {
    const showKeys = this.route.snapshot.queryParamMap.get('showKeys');

    this.translateSubscription = this.translate.onLangChange.subscribe((lang) => {

      sessionStorage.setItem('language', lang.lang);
      this.caseDataService.update(caseData => {
        caseData.reporter.preferredLanguage = lang.lang;
      });
      if (showKeys){
        this.caseDataService.saveSessionData(CaseSessionData.SHOW_KEYS, CaseSessionData.SHOW_KEYS);
      }
      this.loggingService.addMDC(MDC.Language, lang.lang);
    });

    this.languageService.setApplicationLanguage();

  }
  ngOnDestroy(): void {
    this.translateSubscription.unsubscribe();
  }

  ngAfterContentInit(): void {
  }

}
